<template>
    <div class="pageContainer">
        <a-row class="makeOutAnInvoice">
            <a-row class="titleRow">
                <span class="longString"></span>
                发票信息
            </a-row>
            <a-divider class="border" />

            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol" class="formBox">
                <a-form-model-item label="抬头类型">
                    <a-select @change="regionChange" v-model="form.region" placeholder="请选择抬头类型">
                        <a-select-option :value="1">
                            企业单位
                        </a-select-option>
                        <a-select-option :value="2">
                            个人/非企业单位
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item v-if="form.region == 1" ref="name" label="公司名称" prop="name">
                    <a-input v-model="form.name" placeholder="请输入您的公司名称"> </a-input>
                </a-form-model-item>
                <a-form-model-item v-if="form.region == 1" ref="certificateNo" label="公司税号" prop="certificateNo">
                    <a-input v-model="form.certificateNo" placeholder="请输入您的公司税号" :maxLength="20"> </a-input>
                </a-form-model-item>
                <a-form-model-item v-if="form.region == 1" ref="address" label="公司地址" prop="address">
                    <a-input v-model="form.address" placeholder="请输入您的公司地址"> </a-input>
                </a-form-model-item>
                <a-form-model-item v-if="form.region == 1" ref="bankName" label="开户行" prop="bankName">
                    <a-input v-model="form.bankName" placeholder="请输入您的开户行"> </a-input>
                </a-form-model-item>
                <a-form-model-item v-if="form.region == 1" ref="bankAcount" label="开户账号" prop="bankAcount">
                    <a-input v-model="form.bankAcount" placeholder="请输入您的开户账号" :maxLength="19"> </a-input>
                </a-form-model-item>
                <a-form-model-item v-if="form.region == 2" ref="idCard" label="身份证" prop="idCard">
                    <a-input v-model="form.idCard" placeholder="请输入您的身份证" :maxLength="18"> </a-input>
                </a-form-model-item>
                <a-form-model-item ref="userPhone" label="联系电话" prop="userPhone">
                    <a-input v-model="form.userPhone" placeholder="请输入您的联系电话" :maxLength="11"> </a-input>
                </a-form-model-item>
                <a-form-model-item ref="email" label="电子邮箱" prop="email">
                    <a-input v-model="form.email" placeholder="请输入您接收电子发票的邮箱"> </a-input>
                </a-form-model-item>
                <a-form-model-item ref="mailingAddress" label="邮寄地址" prop="mailingAddress">
                    <a-input v-model="form.mailingAddress" placeholder="请输入您的收票地址，以便接收纸质发票"> </a-input>
                </a-form-model-item>
                <a-form-model-item ref="remark" label="更多信息" prop="remark">
                    <a-textarea v-model="form.remark" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
                <a-form-model-item label="总金额">
                    <span class="money">{{ form.money }}</span
                    >元
                </a-form-model-item>
                <a-divider class="border" />
                <a-form-model-item :wrapper-col="{ span: 14, offset: 1 }">
                    <a-button @click="back" class="footer">
                        返回
                    </a-button>
                    <a-button type="primary" :disabled="form.money == 0" @click="onSubmit">
                        提交
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </a-row>
        <NotarizeInvoice :visible="visible" :closeModal="closeModal" :invoiceData="invoiceData" />
    </div>
</template>
<script>
import NotarizeInvoice from "./components/NotarizeInvoice";
export default {
    components: {
        NotarizeInvoice,
    },
    data() {
        const _this = this;
        let chekckUserPhone = (rule, value, callback) => {
            let reg = new RegExp(/^1[3456789]\d{9}$/);
            if (value && value != "") {
                if (!reg.test(value)) {
                    callback(new Error("请输入您正确的电话号码"));
                    return;
                } else {
                    callback();
                }
            } else {
                callback(new Error("请输入您的电话号码"));
            }
        };
        let chekckCertificateNo = (rule, value, callback) => {
            let reg = new RegExp(/^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/g);
            if (value && value != "") {
                if (!reg.test(value)) {
                    callback(new Error("请输入您正确的公司税号"));
                    return;
                } else {
                    callback();
                }
            } else {
                callback(new Error("请输入您的公司税号"));
            }
        };
        let chekckBankAcount = (rule, value, callback) => {
            let reg = new RegExp(/^\d{16,19}$/g);
            if (value && value != "") {
                if (!reg.test(value)) {
                    callback(new Error("请输入您正确的开户账号"));
                    return;
                } else {
                    callback();
                }
            } else {
                callback(new Error("请输入您的开户账号"));
            }
        };
        let chekckIdCard = (rule, value, callback) => {
            let reg = new RegExp(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/g);
            if (value && value != "") {
                if (!reg.test(value)) {
                    callback(new Error("请输入您正确的身份证号码"));
                    return;
                } else {
                    callback();
                }
            } else {
                callback(new Error("请输入您的身份证号码"));
            }
        };
        return {
            visible: false,
            labelCol: { span: 2 },
            wrapperCol: { span: 5 },
            form: {
                region: 1,
                name: undefined,
                certificateNo: undefined, //税号
                address: undefined,
                bankName: undefined,
                bankAcount: undefined,
                remark: undefined,
                email: undefined,
                userPhone: undefined,
                money: 0,
                idCard: undefined,
                mailingAddress: undefined,
            },
            rules: {
                idCard: [{ required: true, validator: chekckIdCard, trigger: "change" }],
                name: [{ required: true, message: "请输入您的公司名称", trigger: "blur" }],
                address: [{ required: true, message: "请输入您的公司地址", trigger: "blur" }],
                mailingAddress: [{ required: true, message: "请输入您的收票地址", trigger: "blur" }],
                userPhone: [{ required: true, validator: chekckUserPhone, trigger: "change" }],
                bankName: [{ required: true, message: "请输入您的开户行", trigger: "blur" }],
                bankAcount: [{ required: true, validator: chekckBankAcount, trigger: "change" }],
                certificateNo: [{ required: true, validator: chekckCertificateNo, trigger: "change" }],
                email: [
                    { required: true, message: "请输入您接收电子发票的邮箱", trigger: "blur" },
                    { type: "email", message: "邮箱格式不正确", trigger: "blur" },
                ],
            },
            invoiceData: {},
        };
    },
    created() {
        let userCode = this.$store.getters.userInfo.userCode;
        let orderParameters = this.$route.query.type;
        let idList = null;
        if (this.$route.query.idList) {
            idList = this.$route.query.idList;
        }
        let url = "/accttax/getPrice?userCode=" + userCode + "&orderParameters=" + orderParameters;
        if (idList) {
            url = "/accttax/getPrice?userCode=" + userCode + "&orderParameters=" + orderParameters + "&idList=" + idList;
        }
        this.$http
            .get(url)
            .then((res) => {
                if (res.rescode == 200) {
                    this.form.money = res.data.price;
                } else {
                    this.$message.warning(res.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
    methods: {
        regionChange() {
            console.log(1);
            this.$refs.ruleForm.resetFields();
        },
        back() {
            this.$router.go(-1);
        },
        closeModal() {
            this.visible = false;
        },
        onSubmit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.visible = true;
                    this.invoiceData = this.form;
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm() {
            this.$refs.ruleForm.resetFields();
        },
    },
};
</script>
<style lang="less" scoped>
.pageContainer {
    .makeOutAnInvoice {
        background: #fff;
        height: 100%;
        overflow: hidden auto;
    }
    .titleRow {
        margin-top: 30px;
        font-size: 20px;
        position: relative;
        margin-left: 20px;
        .longString {
            width: 3px;
            height: 25px;
            background-color: #2d7bf8;
            position: absolute;
            top: -3px;
            left: -10px;
        }
    }
    .border {
        margin: 20px 0;
    }
    .formBox {
        margin-left: 20px;
        .ant-row {
            margin-bottom: 16px;
        }
        .money {
            color: red;
            font-size: 20px;
        }
        .footer {
            margin-right: 20px;
        }
    }
}
</style>
