<template>
    <a-modal class="illegalAdSubModal" title="确认发票信息" :maskClosable="false" okText="提交" cancelText="关闭" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
        <template v-if="invoiceData.region == 1">
            <a-row class="row"> 公司名称：{{ invoiceData.name || "--" }} </a-row>
            <a-row class="row"> 公司税号：{{ invoiceData.certificateNo || "--" }} </a-row>
            <a-row class="row"> 地址：{{ invoiceData.address || "--" }} </a-row>
            <a-row class="row"> 开户行：{{ invoiceData.bankName || "--" }} </a-row>
            <a-row class="row"> 开户账号：{{ invoiceData.bankAcount || "--" }} </a-row>
            <a-row class="row"> 联系电话：{{ invoiceData.userPhone || "--" }} </a-row>
            <a-row class="row"> 电子邮箱：{{ invoiceData.email || "--" }} </a-row>
            <a-row class="row">
                开票金额：
                <span class="red">{{ invoiceData.money || 0 }}</span>
                元
            </a-row>
        </template>
        <template v-else>
            <a-row class="row"> 身份证：{{ invoiceData.idCard || "--" }} </a-row>
            <a-row class="row"> 联系电话：{{ invoiceData.userPhone || "--" }} </a-row>
            <a-row class="row"> 电子邮箱：{{ invoiceData.email || "--" }} </a-row>
        </template>
        <a-row class="row">
            <a-col :span="4">备注：</a-col>
            <a-col :span="20">
                <a-textarea v-model="invoiceData.remark" readOnly></a-textarea>
            </a-col>
        </a-row>
    </a-modal>
</template>

<script>
export default {
    props: ["visible", "closeModal", "invoiceData"],
    data() {
        return {
            confirmLoading: false,
            phone: null,
            password: null,
            password2: null,
        };
    },
    methods: {
        handleCancel() {
            this.closeModal();
        },
        handleOk() {
            this.confirmLoading = true;
            let params = {
                objectType: this.invoiceData.region,
                userPhone: this.invoiceData.userPhone,
                remark: this.invoiceData.remark,
                amount: this.invoiceData.money,
                email: this.invoiceData.email,
                userCode: this.$store.getters.userInfo.userCode,
                mailingAddress: this.invoiceData.mailingAddress,
                type: 1,
                orderParameters: this.$route.query.type,
            };
            if (this.invoiceData.region == 1) {
                params.name = this.invoiceData.name;
                params.dutyParagraph = this.invoiceData.dutyParagraph;
                params.address = this.invoiceData.address;
                params.bankName = this.invoiceData.bankName;
                params.bankAcount = this.invoiceData.bankAcount;
            } else {
                params.idCard = this.invoiceData.idCard;
            }
            if (this.$route.query.idList) {
                params.idList = this.$route.query.idList;
            }
            this.$http
                .post("/accttax/add", params)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.confirmLoading = false;
                        this.$message.success("提交开票成功");
                        this.$router.push({
                            name: "FinancialManagement",
                            query: {},
                        });
                    } else {
                        this.$message.warning(res.msg);
                        this.confirmLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.confirmLoading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
.illegalAdSubModal {
    /deep/ .userPhoneSmsInput {
        width: 78%;
    }
    .row {
        margin-top: 10px;
        margin-left: 20px;
        font-size: 16px;
        .red {
            color: red;
            font-size: 20px;
        }
    }
}
</style>
